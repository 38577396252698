import React, {setState} from "react";
import "./Max.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact(props) {
  return (
    <div className="max">
      <h1>Max</h1>
    </div>
  );
};